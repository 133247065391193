<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col class="main-col-margin">
        <v-card class="mx-auto">
          <v-card-title
            class="main-card-title-offset"
          >
            <v-icon large left color="white"> mdi-format-list-bulleted </v-icon>
            <span class="title">{{ $t('vendor.list.title') }}</span>
            <v-spacer></v-spacer>

            <v-btn icon @click="createVendor()">
              <v-icon color="white">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="vendors"
            :sort-by="[]"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('vendor.list.loading')"
          >
          
            <template v-slot:[`item.controls`]="props">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                @click="editVendor(props.item)"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="openDeleteVendorDialog(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">
            {{ $t('vendor.list.delete.title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('vendor.list.delete.description') }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin:0px">
              <v-col >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteVendor()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('vendor.list.delete.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteVendorDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('vendor.list.delete.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "ListVendors",
  data() {
    return {
      isLoading: true,
      headers: [
        { text: this.$t('vendor.list.label.vendor'), align: "start", value: "name" },
        { text: this.$t('vendor.list.label.email'), value: "email" },
        { text: this.$t('vendor.list.label.adress'), value: "adress" },
        {
          text: this.$t('vendor.list.label.actions'),
          value: "controls",
          sortable: false,
          align: "center",
        },
      ],
      vendors: [],
      dialog: false,
      deletingVendor: null,
      error: null,
    };
  },
  mounted() {
    this.fetchVendors();
  },
  methods: {
    fetchVendors() {
      this.$axios({
        method: "get",
        url: this.$globals.api.vendors.getAll,
      })
        .then((response) => {
          this.vendors = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    createVendor() {
      this.$router.push({ name: "vendor/add" });
    },
    editVendor(vendors) {
      this.$router.push({ name: "vendor/edit", params: { id: vendors.id } });
    },
    openDeleteVendorDialog(vendor) {
      this.deletingVendor = vendor
      this.dialog = true
    },
    closeDeleteVendorDialog() {
      this.deletingVendor = null
      this.dialog = false;
    },
    deleteVendor() {
      this.$axios({
        method: "post",
        url: this.$globals.api.vendors.delete + this.deletingVendor.id,
      })
        .then(() => {
          this.deletingVendor = null
          this.dialog = false
          this.isLoading = true
          this.fetchVendors()
        })
        .catch((error) => {
          this.error = error.response;
        });
    }, 
  },
};
</script>